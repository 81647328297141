import React from 'react'
import "./portfolio.css"
import portfolio1 from "../../assets/portfolio1.png"
import wwwhere from "../../assets/wwwhere.png"
import pihenry from "../../assets/pihenry.png"
import { useTranslation } from "react-i18next"

const data = [
  {
    id: 1,
    image: wwwhere,
    title: "Wwwhere",
    github: "https://github.com/Gasnis/Grupo13-PF",
    demo: "https://wwwhere.up.railway.app/",
  },
  {
    id: 2,
    image: pihenry,
    title: "VideoGames project",
    github: "https://github.com/ThomRojas/PI-Henry-project-VIDEO-GAMES",
    demo: "https://github.com/ThomRojas/PI-Henry-project-VIDEO-GAMES",
  },
  {
    id: 3,
    image: portfolio1,
    title: "Comming",
    github: "https://github.com/thomrojas",
    demo: "https://github.com/thomrojas",
  },
  // {
  //   id: 4,
  //   image: portfolio1,
  //   title: "My project",
  //   github: "https://github.com",
  //   demo: "https://github.com/thomrojas",
  // },
  // {
  //   id: 5,
  //   image: portfolio1,
  //   title: "My project",
  //   github: "https://github.com",
  //   demo: "https://github.com/thomrojas",
  // },
  // {
  //   id: 6,
  //   image: portfolio1,
  //   title: "My project",
  //   github: "https://github.com",
  //   demo: "https://github.com/thomrojas",
  // },
]



const Portfolio = () => {
  const [t, i18n] = useTranslation("global")

  return (
    <section id="portfolio">
      <h2>{t("portfolio.title1")}</h2>

      <div className="container portfolio__container">
        {
          data.map(({id, image, title, github, demo}) => {
            return (
            <article key={id} className='portfolio__item'>
              <div className="portfolio__item-image">
                  <img src={image} alt={title} />
                <h3>{title}</h3>
                <div className="portfolio__item-cta">
                <a href={github} className='btn' target="_blank" rel='noreferrer'>GitHub</a>
                <a href={demo} className="btn btn-primary" target="_blank" rel='noreferrer'>Live Demo</a>
                </div>
              </div>
            </article>
           )})
        }
      </div>
    </section >
  )
}

export default Portfolio
