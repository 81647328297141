import React from "react";
import "./testimonials.css";
import AVTR1 from "../../assets/avtr1.png";

import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import { useTranslation, i18n } from "react-i18next";

const Testimonials = () => {
  const [t, i18n] = useTranslation("global");

  const data = [
    {
      name: t("testimonials.soft-skills"),
      skill1: t("testimonials.soft-skills-1"),
      skill2: t("testimonials.soft-skills-2"),
      skill3: t("testimonials.soft-skills-3"),
      skill4: t("testimonials.soft-skills-4"),
      skill5: t("testimonials.soft-skills-5"),
    },
    {
      name: t("testimonials.hard-skills"),
      skill1: t("testimonials.hard-skills-1"),
      skill2: t("testimonials.hard-skills-2"),
      skill3: t("testimonials.hard-skills-3"),
      skill4: t("testimonials.hard-skills-4"),
      skill5: t("testimonials.hard-skills-5"),
    },
  ];

 

  return (
    <section id="skills">
      <h2>{t("testimonials.t1")} {t("testimonials.t2")}</h2>


      <Swiper className="container testimonials__container"
      modules={[Pagination]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{clickable: true}}
      >
        {
          data.map(({ name, skill1, skill2, skill3, skill4, skill5}, index)=> {
            return(
              <SwiperSlide key={index} className='testimonial'>
                <div className="client__avatar">
                  
                </div>
                <h5 className='client__name'>{name}</h5>
                 <small className='client__review'>
                  {skill1}<br/>
                  {skill2}<br/>
                  {skill3}<br/>
                  {skill4}<br/>
                  {skill5}
                </small>
             </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  )
}

export default Testimonials
