import React from 'react'
import "./header.css"
import CTA from "./CTA"
import ME from "../../assets/img.png"
import HeaderSocials from './HeaderSocials'
import {BsArrowDownSquare} from "react-icons/bs"
import { useTranslation } from "react-i18next"
import { US } from 'country-flag-icons/react/3x2'

const Header = () => {
  <US title="United States" />

    const [t, i18n] = useTranslation("global")


  return (
    <header id="header">
      
      <div className="container header_container">
       
        <br/>
       
        <div className="b2_container">
        <button className='btn btn-primary' onClick={() => i18n.changeLanguage("es")}>ES</button> 
        <button className='btn btn-primary' onClick={() => i18n.changeLanguage("en")}>EN</button>
        </div>
        <br/>
        <br/>
        <h1>{t("header.hello-world")}</h1>
        <h5 className="text-light">{t("header.developer")}</h5>
        <CTA />
        {/* <HeaderSocials /> */}

        <div className="me">
          <img src={ME} alt="me" />
        </div>


        

        <a href="#contact" className='scroll__down'><BsArrowDownSquare /></a>
      </div>
    </header>
  )
}

export default Header
