import React from 'react'
import CVEnglish from "../../assets/cv.pdf"
import CVSpanish from "../../assets/CVesp.pdf"
import { useTranslation } from "react-i18next"

const CTA = () => {
  const [t, i18n] = useTranslation("global")
  return (
    <div className='cta'>
        <a href={CVEnglish} target="_blank" className="btn" >{t("header.cta1")}</a>
        <a href={CVSpanish} target="_blank" className="btn">{t("header.cta3")}</a>
        <a href="#contact" className='btn btn-primary'>{t("header.lets-Talk")}</a>
      
    </div>
  )
}

export default CTA
