import React, { useRef, useEffect, useState } from "react";
import "./contact.css";
import { MdOutlineEmail } from "react-icons/md";
import { RiMessengerLine } from "react-icons/ri";
import { BsWhatsapp } from "react-icons/bs";
import { BsArrowUpSquare } from "react-icons/bs";
import { BsCalendarPlus } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";

import emailjs from "emailjs-com";

const Contact = () => {
  const [t, i18n] = useTranslation("global");
  const form = useRef();
  const [showScrollUp, setShowScrollUp] = useState(false);

  const showAlert = () => {
    swal({
      title: t("contact.alertTitle"),
      text: t("contact.alertText"),
      icon: "success",
      button: "Accept",
    });
  };

  const handleScroll = () => {
    if (window.pageYOffset > 100) {
      setShowScrollUp(true);
    } else {
      setShowScrollUp(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_i2mfsiu",
      "template_cpuoace",
      form.current,
      "bp2d8FJMGGPwr8d0c"
    );

    e.target.reset();
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <section id="contact">
      <h2>{t("contact.c2")}</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className="contact__option-icon" />
            <h4>{t("contact.c3")}</h4>
            {/* <span>thomas.rojas.arce@gmail.com</span> */}
            <br />
            <a href="mailto:thomas.rojas.arce@gmail.com" target="_blank">
              {t("contact.c4")}
            </a>
          </article>
          <article className="contact__option">
            <BsCalendarPlus className="contact__option-icon" />
            <br />
            {/* <h4>Schedule a meeting</h4> */}
            <h4>Calendly</h4>
            <br />
            <a href="https://www.calendly.com/thomrojas" target="_blank">
              {t("contact.cal")}
            </a>
          </article>
          <article className="contact__option">
            <BsWhatsapp className="contact__option-icon" />
            <h4>WhatsApp</h4>
            {/* <span>+56964331354</span> */}
            <br />
            <a href="https://wa.me/+56964331354" target="_blank">
              {t("contact.c8")}
            </a>
          </article>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            name="name"
            placeholder={t("contact.c5")}
            pattern="^[A-Za-z ]+$"
            required
          />
          <input
            type="email"
            name="email"
            placeholder={t("contact.c6")}
            pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$"
            required
          />
          <textarea
            name="message"
            rows="7"
            placeholder={t("contact.c7")}
            minLength="10"
            required
          ></textarea>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={() => {
              showAlert();
            }}
          >
            {t("contact.c8")}
          </button>
        </form>
        {showScrollUp && (
          <BsArrowUpSquare className="scroll-up-icon" onClick={scrollToTop} />
        )}
      </div>
    </section>
  );
};

export default Contact;
