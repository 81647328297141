import React from 'react'
import "./experience.css"
import {HiCheckCircle} from "react-icons/hi"
import {AiFillStar} from "react-icons/ai"
import {useTranslation} from "react-i18next";



const Experience = () => {
  const [t, i18n] = useTranslation("global")

  return (
    <section id="experience">
      <h2>{t("experience.techSkills")}</h2>

      <div className="container experience__container ">
        <div className="experience__frontend">
          <h3>{t("experience.fe")}</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <HiCheckCircle className='experience__details-icon' />
              <div>
                <h4>HTML</h4>
                <small className='text-light1'><AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/></small>
                </div>
            </article>
            <article className='experience__details'>
              <HiCheckCircle className='experience__details-icon' />
              <div>
                <h4>CSS</h4>
                <small className='text-light1'><AiFillStar/><AiFillStar/><AiFillStar/></small>
              </div>
            </article>
            <article className='experience__details'>
              <HiCheckCircle  className='experience__details-icon' />
              <div>
                <h4>Javascript</h4>
                <small className='text-light1'><AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/></small>
              </div>
            </article>
            <article className='experience__details'>
              <HiCheckCircle className='experience__details-icon' />
              <div>
                <h4 className='toRepair'>Bootstrap</h4>
                <small className='text-light1'><AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/></small>
              </div>
            </article>
            <article className='experience__details'>
              <HiCheckCircle  className='experience__details-icon'/>
              <div>
                <h4>Tailwind</h4>
                <small className='text-light1'><AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/></small>
              </div>
            </article>
            <article className='experience__details'>
              <HiCheckCircle  className='experience__details-icon'/>
              <div>
                <h4>React</h4>
                <small className='text-light1'><AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/></small>
              </div>
            </article>
          </div>
        </div>
        <div className="experience__backend">
        <h3>{t("experience.be")}</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <HiCheckCircle  className='experience__details-icon'/>
              <div>
                <h4>NodeJs</h4>
                <small className='text-light1'><AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/></small>
              </div>
            </article>
            <article className='experience__details'>
              <HiCheckCircle className='experience__details-icon' />
              <div>
                <h4>MongoDB</h4>
                <small className='text-light1'><AiFillStar/><AiFillStar/><AiFillStar/></small>
              </div>
            </article>
            <article className='experience__details'>
              <HiCheckCircle  className='experience__details-icon'/>
              <div>
                <h4>Express</h4>
                <small className='text-light1'><AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/></small>
              </div>
            </article>
            <article className='experience__details'>
              <HiCheckCircle className='experience__details-icon' />
              <div>
                <h4>Sequelize</h4>
                <small className='text-light1'><AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/></small>
              </div>
            </article>
            <article className='experience__details'>
              <HiCheckCircle className='experience__details-icon' />
              <div>
                <h4>Postgress</h4>
                <small className='text-light1'><AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/></small>
              </div>
            </article>
            <article className='experience__details'>
              <HiCheckCircle  className='experience__details-icon'/>
              <div>
                <h4 className='toRepair'>MySql</h4>
                <small className='text-light1'><AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/></small>
              </div>
            </article>
            </div>
        </div>
      </div>
    </section>
  )
}

export default Experience
