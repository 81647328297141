import React from 'react'
import "./about.css"
import ME from "../../assets/img2.png";
import {FaAward} from "react-icons/fa";
import {FiUsers} from "react-icons/fi";
import {RiFolderChartLine} from "react-icons/ri";
import {useTranslation} from "react-i18next";


const About = () => {
  const [t, i18n] = useTranslation("global")


  return (
    <section id="about">
      <h2>{t("about.about-me")}</h2> 

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} className="image" alt="aboutimg" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
              <article className='about__card'>
                <FaAward  className='about__icon'/>
                <h5>{t("about.experience")}</h5>
                <small className='small'>{t("about.hours")}</small>
              </article>

              {/* <article className='about__card'>
                <FiUsers  className='about__icon'/>
                <h5>Clients</h5>
                <small>200+ world wide</small>
              </article> */}

              <article className='about__card'>
                <RiFolderChartLine  className='about__icon'/>
                <h5>{t("about.projects")}</h5>
                <small> {t("about.quantity")}  </small>
              </article>
            </div>
            <div className='paragraph__container'>
            <p className='paragraph'>
            {t("about.paragraph")}
          </p>
            </div>

          {/* <a href="#contact" className='btn btn-primary'>{t("about.lets-Talk")}</a> */}
        </div>

      </div>
    </section>
  )
}

export default About
