import React from 'react'
import "./footer.css"
import {AiOutlineLinkedin} from "react-icons/ai"
import {AiOutlineGithub} from "react-icons/ai"
import {useTranslation} from "react-i18next";

const Footer = () => {
  const [t, i18n] = useTranslation("global")

  return (
    <footer>
      <a href="#" className='footer__logo'>ThomDev</a>

      <ul className='permalinks'>
        <li><a href="#">{t("footer.f1")}</a></li>
        <li><a href="#about">{t("footer.f2")}</a></li>
        <li><a href="#experience">{t("footer.f3")}</a></li>
        <li><a href="#testimonials">{t("footer.f4")}</a></li>
        <li><a href="#portfolio">{t("footer.f5")}</a></li>
        <li><a href="#contact">{t("footer.f6")}</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://linkedin.com/in/thomrojas" target="_blank"><AiOutlineLinkedin /></a>
        <a href="https://github.com/thomrojas" target="_blank"><AiOutlineGithub /></a>
      </div>

     

    </footer>
  )
}

export default Footer
